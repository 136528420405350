@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Shrikhand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=STIX+Two+Math&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500&family=STIX+Two+Math&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500&family=STIX+Two+Math&family=Staatliches&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");

body {
  margin: 0;
  overflow: hidden;
}

/* SMALLER SCREENS BELOW */

@media screen and (max-width: 1020px) {
  .left-side {
    width: 100%;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: none;
    background-color: rgba(241, 255, 86, 255);
  }

  .right-side {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 55vh;
    background-color: white;
    color: white;
  }

  .transition1-left {
    animation: transition1-left;
    animation-duration: 3s;
  }

  .transition1-right {
    animation: transition1-right;
    animation-duration: 3s;
  }

  .transition2-left {
    animation: transition2-left;
    animation-duration: 3s;
  }

  .transition2-right {
    animation: transition2-right;
    animation-duration: 3s;
  }

  @keyframes transition1-left {
    from {
      height: 5vh;
    }

    to {
      height: 45vh;
    }
  }

  @keyframes transition1-right {
    from {
      height: 95vh;
    }

    to {
      height: 55vh;
    }
  }

  @keyframes transition2-left {
    from {
      height: 95vh;
    }

    to {
      height: 45vh;
    }
  }

  @keyframes transition2-right {
    from {
      height: 5vh;
    }

    to {
      height: 55vh;
    }
  }

  .expand-projects-left {
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 5vh;
    background: none;
    background-color: rgba(241, 255, 86, 255);
    box-shadow: none;
    position: relative;
    animation: slideout;
    animation-duration: 2s;
  }

  .expand-projects-right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 95vh;
    width: 100vw;
    background-color: rgb(255, 255, 255);
    color: black;
    font-size: 1em;
    animation: slidein;
    animation-duration: 2s;
    overflow-y: scroll;
  }

  .expand-about-left {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 95vh;
    background: none;
    background-color: rgba(241, 255, 86, 255);
    box-shadow: none;
    position: relative;
    animation: slidein;
    animation-duration: 2s;
  }

  .expand-about-right {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    width: 100vw;
    background-color: rgb(255, 255, 255);
    color: white;
    font-size: 1em;
    animation: slideout;
    animation-duration: 2s;
  }

  @keyframes slidein {
    from {
      height: 50vh;
    }

    to {
      height: 95vh;
    }
  }

  @keyframes slideout {
    from {
      height: 50vh;
    }

    to {
      height: 5vh;
    }
  }

  .title-line1 {
    font-family: "Lexend", sans-serif;
    color: rgba(20, 20, 20, 0.747);
    font-size: 6vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: fit-content;
    width: fit-content;
    padding: 0px;
    position: absolute;
    top: 0vh;
    left: 25vw;
    transform: translateY(-50%);
    transform: translateX(-50%);
  }

  .title-line1 h1 {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: 0.02em;
    animation: typing 0.9s steps(10, end);
  }

  .title-line2 {
    font-family: "Lexend", sans-serif;
    color: rgba(20, 20, 20, 0.747);
    font-size: 7vw;
    display: flex;
    height: fit-content;
    width: fit-content;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px;
    position: absolute;
    bottom: 1vh;
    left: 72vw;
    transform: translateY(-50%);
    transform: translateX(-50%);
  }

  .me-pic {
    background: url("https://i.imgur.com/DmvLUZx.png");
    background-size: 110%;
    background-position-x: 0%;
    background-position-y: 50%;
    height: 25vh;
    width: 25vh;
    margin: 0 auto;
    margin-bottom: -40vh;
    border-radius: 50%;
  }

  .title-line2 h1 {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: 0.02em;
    animation: typing 0.9s 0.9s steps(10, end), delay-appear 1s;
  }

  .title-line2 span {
    color: rgb(143, 26, 143);
  }

  @keyframes delay-appear {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: grey;
    }
  }

  .back-projects {
    font-family: "Open Sans", cursive;
    background-color: rgb(63, 63, 63);
    color: white;
    font-size: 0.66vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    height: 5vh;
    width: 12vh;
    border-radius: 10px;
    padding: 0px;
    position: absolute;
    top: 2.5vh;
    left: 51vw;
    transform: translateY(-50%);
    transform: translateX(-50%);
    animation: slidedown;
    animation-duration: 2.5s;
  }

  .back-about {
    font-family: "Open Sans", cursive;
    background-color: rgb(63, 63, 63);
    color: white;
    font-size: 0.66vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 4vh;
    width: 12vh;
    border-radius: 10px;
    padding: 0px;
    position: absolute;
    bottom: 3vh;
    left: 55vw;
    right: 50vw;
    transform: translateY(-50%);
    transform: translateX(-50%);
    animation: slideup;
    animation-duration: 2.5s;
  }

  @keyframes slidedown {
    from {
      top: -80vh;
    }

    to {
      top: 2.5vh;
    }
  }

  @keyframes slideup {
    from {
      bottom: -80vh;
    }

    to {
      bottom: 3vh;
    }
  }

  .back-projects:hover {
    color: white;
    cursor: pointer;
    transition: 0.25s;
  }

  .back-about:hover {
    color: white;
    cursor: pointer;
    transition: 0.25s;
  }

  h1 {
    font-size: 2.75em;
  }

  .box {
    font-family: "Lexend", sans-serif;
    font-size: 3.5vw;
    text-align: center;
    width: 35vw;
    margin: 0 auto;
    padding-top: 1vh;
    padding-bottom: 1vh;
    border-radius: 2vw;
    border: solid 2px;
    transition: 0.05s;
    line-height: 4vh;
  }

  .about {
    margin-top: -7.5vh;
    margin-bottom: 7.5vh;
    transition: 0.2s;
    color: rgba(0, 0, 0, 0.753);
  }

  .about:hover {
    background-color: rgba(255, 255, 255, 0.418);
    cursor: pointer;
  }

  .projects {
    color: rgb(36, 36, 36);
    transition: 0.2s;
    border: solid 2px;
    margin-top: -5vh;
  }

  .projects:hover {
    background-color: rgba(207, 240, 89, 0.226);
    cursor: pointer;
  }

  .arrow {
    font-size: 1.3em;
    margin-right: 5px;
  }

  .project-card {
    width: 50vw;
    height: 50vw;
    border-radius: 50%;
    box-shadow: 0 0 15px #ccc;
  }

  .twenty {
    background: url("https://i.imgur.com/HLNOBjq.png");
    background-size: 102%;
    background-position-x: 9%;
    background-position-y: 65%;
  }

  .bop {
    background: url("https://i.imgur.com/2iQisOd.png");
    background-size: 240%;
    background-position-x: 49.5%;
    background-position-y: 80%;
  }

  .hike {
    background: url("https://i.imgur.com/3M7asj7.png");
    background-size: 171%;
    background-position-x: 82%;
    background-position-y: 1%;
    box-shadow: 0 0 15px rgb(138, 138, 138);
  }

  .bbb {
    background: url("https://i.imgur.com/vPPiMs8.png");
    background-size: 93%;
    background-position-x: 60%;
    background-position-y: 50%;
    box-shadow: 0 0 20px rgb(87, 86, 86);
  }

  .recipe {
    background: url("https://i.imgur.com/BrcmqPl.png");
    background-size: 145%;
    background-position-x: 48%;
    background-position-y: 35%;
  }
  .calculator {
    background: url("https://i.imgur.com/FEqBsOc.png");
    background-size: 125%;
    background-position-x: 42%;
    background-position-y: 5%;
  }
  .movies {
    background: url("https://i.imgur.com/NaFydgI.png");
    background-size: 110%;
    background-position-x: 50%;
    background-position-y: 50%;
  }
  .zelda {
    background: url("https://i.imgur.com/iLHhiN4.png");
    background-size: 155%;
    background-position-x: 45%;
    background-position-y: 55%;
  }

  .twenty-trendy,
  .bopit,
  .hikeme,
  .barber {
    font-family: "Open Sans", cursive;
    background-color: white;
    color: black;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 2px solid rgb(66, 65, 66);
  }

  .description {
    font-family: "Open Sans", cursive;
    padding-top: 20px;
    font-size: 0.45em;
    background-color: white;
    color: black;
    width: 490.5px;
    height: 230px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 2px solid rgb(66, 65, 66);
  }

  a {
    margin: 10px;
  }

  .description-container {
    border-radius: 5%;
    font-family: lexend;
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 90vw;
    height: 92%;
    background-color: rgb(252, 252, 252);
    box-shadow: 0 0 15px rgb(212, 210, 210);
  }

  .flick {
    margin: 0 auto;
    width: 88vw;
  }

  @keyframes opacity-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .description-container h4 {
    border: 1px solid black;
    border-radius: 5px;
    width: fit-content;
    padding: 2px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .view {
    margin: 0 auto;
    font-size: 3vw;
    display: flex;
    align-items: center;
    border: solid 1px black;
    color: white;
    padding: 3px;
    width: fit-content;
    background-color: rgb(61, 61, 61);
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .view:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: ease-in 100ms;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .projects-container {
    font-family: "Lexend", sans-serif;
    font-size: 2.75vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    height: 70vh;
    align-content: center;
  }

  .projects-container h3 {
    margin-bottom: 10px;
  }

  .upper-proj1-container:hover,
  .upper-proj2-container:hover,
  .lower-proj1-container:hover,
  .lower-proj2-container:hover {
    transform: scale(1.09);
    transition: 300ms ease-in-out;
    cursor: pointer;
  }

  .upper-proj1-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8em;
    text-align: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .upper-proj2-container {
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .lower-proj1-container {
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .lower-proj2-container {
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @keyframes upper-proj1 {
    from {
      margin-top: -90vh;
    }

    to {
      margin-top: 0;
    }
  }

  @keyframes upper-proj2 {
    from {
      margin-top: -135vh;
    }

    to {
      margin-top: 0;
    }
  }

  @keyframes lower-proj1 {
    from {
      margin-bottom: -200vh;
    }

    to {
      margin-bottom: 0;
    }
  }

  @keyframes lower-proj2 {
    from {
      margin-bottom: -500vh;
    }

    to {
      margin-bottom: 0;
    }
  }

  .bio-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 95vh;
    justify-content: center;
    align-items: center;
  }

  .me {
    width: 540px;
    height: 650px;
  }

  .biography {
    width: 100%;
    text-align: center;
    font-family: Lexend;
    font-size: 2.2vh;
  }

  .biography span {
    font-size: 0.7em;
  }
  .biography p {
    font-size: 0.7em;
    text-align: center;
  }

  .github {
    width: 4.3vh;
  }

  .linkedin {
    width: 4.3vh;
  }
  .email {
    width: 4.3vh;
  }

  .download {
    width: 4.3vh;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    list-style-type: none;
    padding: 0;
  }

  .contact-div {
    font-family: lexend;
  }

  .contact-div input,
  textarea {
    width: 95%;
    text-align: center;
    font-family: lexend;
    margin: 10px;
  }

  .contact-div textarea {
    width: 96%;
    height: 10%;
    text-align: left;
    height: 70px;
  }

  .contact-div button {
    width: 97%;
    margin-left: 2%;
    height: 40px;
    background-color: rgb(229, 244, 248);
    border-color: transparent;
    font-family: lexend;
    font-size: 1.4em;
    color: rgb(87, 85, 85);
  }

  .contact-div button:hover {
    cursor: pointer;
  }

  .contact-me:hover {
    cursor: pointer;
  }

  .project-pic {
    width: 58vh;
    height: 28vh;
  }

  .main-bio {
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    width: 85%;
    text-align: justify;
    font-size: 1.5vh;
  }

  .tech-stack {
    margin-top: 0;
    font-size: 1.5vh;
    margin: 0 auto;
  }

  .tech-stack div {
    text-align: center;
  }

  .view-buttons {
    margin: 0 auto;
    text-align: center;
    display: flex;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 8%;
  }

  .code {
    width: 20px;
  }

  .app {
    width: 20px;
  }

  .back-arrow {
    width: 15px;
    height: 20px;
  }

  .main-project-div {
    display: flex;
    align-items: center;
    overflow-y: scroll;
    height: 80vh;
    width: 90vw;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    margin-top: -20%;
  }

  .main-project-div::-webkit-scrollbar {
    display: none;
  }

  .next {
    background-color: transparent;
    border-color: transparent;
    font-size: 2em;
    padding-left: 26px;
    padding-right: 19px;
    padding-top: 14px;
    padding-bottom: 9px;
    border-radius: 50%;
    color: black;
    font-family: "Nanum Myeongjo", serif;
    position: absolute;
  }

  .one {
    padding-left: 19px;
    padding-right: 26px;
    padding-top: 14px;
    padding-bottom: 9px;
    color: black;
    left: 0vw;
  }

  .two {
    right: 0.25vw;
  }

  .two:hover {
    background-color: rgba(236, 236, 236, 0.479);
    cursor: pointer;
    transition: ease-in 100ms;
  }

  .three {
    padding-left: 19px;
    padding-right: 26px;
    padding-top: 14px;
    padding-bottom: 9px;
  }

  .three:hover {
    background-color: rgba(236, 236, 236, 0.479);
    cursor: pointer;
  }

  .animate__animated.animate__backInDown {
    --animate-duration: 2s;
  }

  .container {
    position: relative;
    width: 50%;
  }

  .skills-sect {
    display: grid;
    width: 95%;
    height: 15vh;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin-left: 4%;
  }

  .skills-sect1 {
    font-family: lexend;
    font-size: 1.85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15vh;
  }

  .skills-sect1 div {
    border: solid 1.5px black;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.308);
    color: white;
  }

  .skills-sect1 div:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: ease-in-out 200ms;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 35px;
    width: 35px;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .container:hover .overlay {
    opacity: 1;
  }

  .text {
    border-top: 1px solid white;
    font-family: lexend;
    color: white;
    font-size: 0.4em;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .top-half-bio {
    animation: fade-in-down 2s;
    height: 35vh;
    width: 70vw;
    display: flex;
    margin-left: 11vw;
    margin-top: -20vw;
    margin-bottom: 0vh;
    justify-content: center;
    flex-direction: column;
  }

  .hover-underline {
    text-decoration: underline;
    margin: 0px;
    padding: 0px;
  }

  .hover-underline:hover {
    text-decoration: none;
  }

  .bottom-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 95%;
    height: 45vh;
    margin-left: 17vw;
    margin-top: 0vh;
  }

  @keyframes fade-in-down {
    from {
      margin-top: -300vw;
    }

    to {
      margin-top: -20vw;
    }
  }

  .bottom-container h2 {
    text-align: center;
    font-size: 1em;
  }

  .trait-1,
  .trait-2,
  .trait-3,
  .trait-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2vh;
    width: 90%;
    height: 100%;
    font-family: lexend;
  }

  .icon {
    margin-right: 5%;
  }

  .icon img {
    width: 4vh;
  }

  .bottom-container p {
    width: 90%;
    margin: 0 auto;
    font-size: 1vh;
    height: 100%;
    text-align: center;
  }

  .side-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 12vw;
    color: white;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(58, 58, 58);
  }

  .social-text {
    color: white;
    font-family: lexend;
    border-top: 1px solid white;
    margin-top: 8%;
    font-size: 1.8vw;
    padding-top: 3px;
  }

  .social-container {
    font-size: 1.8vh;
    display: flex;
    opacity: 0.7;
  }

  .social-container:hover {
    opacity: 1;
    transition: ease-in 200ms;
  }

  .close-tech {
    float: right;
    margin-top: -5%;
    background-color: rgba(0, 0, 0, 0.356);
    border-color: white;
    font-family: lexend;
    font-size: 0.75em;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 50%;
    color: white;
  }

  .close-tech:hover {
    cursor: pointer;
    transition: ease-in 150ms;
  }

  .toggle-switch-div {
    font-family: "Quicksand", sans-serif;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    position: absolute;
    top: 1vh;
    right: 1vh;
    color: black;
    z-index: 1;
  }
  .toggle-switch-div div {
    font-size: 0.8em;
    margin-bottom: 5px;
  }
  .switch {
    position: relative;
    display: block;
    width: 55px;
    height: 25px;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: -2px;
    left: 0;
    right: -1px;
    bottom: 0px;
    background: white;
    transition: 0.5s;
    border: 2px solid black;
  }

  .slider:before {
    position: absolute;
    content: "";
    bottom: 10px;
    background-color: black;
    transition: 0.5s;
  }

  .switch input {
    display: none;
  }

  input:checked + .slider {
    background-color: black;
  }

  input:checked + .slider:before {
    transform: translateX(35px) rotateZ(46deg);
    background: rgba(241, 255, 86, 255);
  }

  .slider.round {
    border-radius: 35px;
  }

  .slider.round:before {
    width: 30%;
    height: 40%;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    transform: rotateZ(-45deg);
  }

  .dark-mode-left {
    background-color: rgba(0, 0, 0, 0.822);
    animation: dark-to-light-left 700ms;
    color: rgb(202, 202, 202);
  }

  @keyframes dark-to-light-left {
    from {
      background-color: rgba(241, 255, 86, 255);
    }

    to {
      background-color: rgba(0, 0, 0, 0.822);
    }
  }

  .dark-mode-right {
    background-color: rgb(46, 90, 104);
    animation: dark-to-light-right 500ms;
    color: rgb(202, 202, 202);
  }

  @keyframes dark-to-light-right {
    from {
      background-color: rgb(255, 255, 255);
    }

    to {
      background-color: rgb(46, 90, 104);
    }
  }

  @keyframes light-to-dark-right {
    from {
      background-color: rgb(27, 70, 90);
    }

    to {
      background-color: rgba(241, 255, 86, 255);
    }
  }
}
/* LARGER SCREENS BELOW */

@media screen and (min-width: 1020px) {
  .wrapper {
    display: flex;
    min-height: 100vh;
  }

  .left-side {
    width: 50%;
    height: 100vh;
    background-color: rgba(241, 255, 86, 255);
    position: relative;
  }

  .right-side {
    width: 50%;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    color: white;
    font-size: 1em;
  }

  .transition1-left {
    animation: transition1-left;
    animation-duration: 3s;
  }

  .transition1-right {
    animation: transition1-right;
    animation-duration: 3s;
  }

  .transition2-left {
    animation: transition2-left;
    animation-duration: 3s;
  }

  .transition2-right {
    animation: transition2-right;
    animation-duration: 3s;
  }

  @keyframes transition1-left {
    from {
      width: 5%;
    }

    to {
      width: 50%;
    }
  }

  @keyframes transition1-right {
    from {
      width: 95%;
    }

    to {
      width: 50%;
    }
  }

  @keyframes transition2-left {
    from {
      width: 95%;
    }

    to {
      width: 50%;
    }
  }

  @keyframes transition2-right {
    from {
      width: 5%;
    }

    to {
      width: 50%;
    }
  }

  .expand-projects-left {
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 5%;
    background: none;
    background-color: rgba(241, 255, 86, 255);
    box-shadow: none;
    position: relative;
    animation: slideout;
    animation-duration: 2s;
  }

  .expand-projects-right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    color: black;
    font-size: 1em;
    animation: slidein;
    animation-duration: 2s;
  }

  .expand-about-left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 95%;
    background: none;
    background-color: rgba(241, 255, 86, 255);
    box-shadow: none;
    position: relative;
    animation: slidein;
    animation-duration: 2.5s;
  }

  .expand-about-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5%;
    height: 100vh;
    color: white;
    font-size: 1em;
    animation: slideout;
    animation-duration: 2.5s;
  }

  @keyframes slidein {
    from {
      width: 50%;
    }

    to {
      width: 95%;
    }
  }

  @keyframes slideout {
    from {
      width: 50%;
    }

    to {
      width: 5%;
    }
  }

  .title-line1 {
    font-family: "Lexend", sans-serif;
    color: rgba(20, 20, 20, 0.747);
    /* color: rgb(198, 235, 161); */
    font-size: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: fit-content;
    width: fit-content;
    padding: 0px;
    position: absolute;
    top: 0vh;
    left: 18vw;
    right: 50vw;
    transform: translateY(-50%);
    transform: translateX(-50%);
  }

  .title-line1 h1 {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: 0.02em;
    animation: typing 0.9s steps(10, end);
  }

  .title-line2 {
    font-family: "Lexend", sans-serif;
    color: rgba(20, 20, 20, 0.747);
    font-size: 4vw;
    display: flex;
    height: fit-content;
    width: fit-content;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px;
    position: absolute;
    top: 68vh;
    left: 84vw;
    transform: translateY(-50%);
    transform: translateX(-50%);
  }

  .me-pic {
    position: absolute;
    background: url("https://i.imgur.com/DmvLUZx.png");
    background-size: 110%;
    background-position-x: 0%;
    background-position-y: 50%;
    height: 18vw;
    width: 18vw;
    top: 30vh;
    left: 41vw;
    border-radius: 50%;
  }

  .title-line2 h1 {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: 0.02em;
    animation: typing 0.9s 0.9s steps(10, end), delay-appear 1s;
  }

  .title-line2 span {
    color: rgb(143, 26, 143);
  }

  @keyframes delay-appear {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: grey;
    }
  }

  .back-projects {
    font-family: "Open Sans", cursive;
    background-color: rgb(63, 63, 63);
    color: white;
    font-size: 0.66vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    height: 11vh;
    width: 6.5vh;
    border-radius: 10px;
    padding: 0px;
    position: absolute;
    top: 42vh;
    left: 5vw;
    right: 50vw;
    transform: translateY(-50%);
    transform: translateX(-50%);
    animation: slideleft;
    animation-duration: 2.5s;
  }

  .back-about {
    font-family: "Open Sans", cursive;
    background-color: rgb(63, 63, 63);
    color: white;
    font-size: 0.66vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 11vh;
    width: 6.5vh;
    border-radius: 10px;
    padding: 0px;
    position: absolute;
    top: 42vh;
    left: 95vw;
    right: 50vw;
    transform: translateY(-50%);
    transform: translateX(-50%);
    animation: slideright;
    animation-duration: 2.5s;
  }

  @keyframes slideleft {
    from {
      left: -80vw;
    }

    to {
      left: 5vw;
    }
  }

  @keyframes slideright {
    from {
      left: 130vw;
    }

    to {
      left: 95vw;
    }
  }

  .back-projects:hover {
    color: white;
    cursor: pointer;
    transition: 0.25s;
  }

  .back-about:hover {
    color: white;
    cursor: pointer;
    transition: 0.25s;
  }

  h1 {
    font-size: 2.75em;
  }

  .box {
    font-family: "Lexend", sans-serif;
    font-size: 1.4vw;
    text-align: center;
    width: 13vw;
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 1vw;
    padding-right: 1vw;
    border-radius: 15px;
    border: solid 3px;
    transition: 0.05s;
    line-height: 4vh;
    margin-top: 45vh;
  }

  .about {
    transition: 0.2s;
    color: rgba(0, 0, 0, 0.753);
  }

  .about:hover {
    cursor: pointer;
    color: rgb(224, 224, 224);
  }

  .projects {
    color: rgb(36, 36, 36);
    transition: 0.2s;
    border: solid 3px;
  }

  .projects:hover {
    cursor: pointer;
    color: rgb(201, 221, 17);
  }

  .hovah:hover {
    color: rgb(212, 212, 212);
  }

  .arrow {
    font-size: 1.3em;
    margin-right: 5px;
  }

  .project-card {
    width: 33vh;
    height: 33vh;
    border-radius: 50%;
    box-shadow: 0 0 15px #ccc;
  }

  .twenty {
    background: url("https://i.imgur.com/HLNOBjq.png");
    background-size: 102%;
    background-position-x: 9%;
    background-position-y: 65%;
  }

  .bop {
    background: url("https://i.imgur.com/2iQisOd.png");
    background-size: 240%;
    background-position-x: 49.5%;
    background-position-y: 80%;
  }

  .hike {
    background: url("https://i.imgur.com/3M7asj7.png");
    background-size: 171%;
    background-position-x: 82%;
    background-position-y: 1%;
    box-shadow: 0 0 15px rgb(138, 138, 138);
  }

  .bbb {
    background: url("https://i.imgur.com/vPPiMs8.png");
    background-size: 93%;
    background-position-x: 60%;
    background-position-y: 50%;
    box-shadow: 0 0 20px rgb(87, 86, 86);
  }

  .recipe {
    background: url("https://i.imgur.com/BrcmqPl.png");
    background-size: 145%;
    background-position-x: 48%;
    background-position-y: 35%;
  }
  .calculator {
    background: url("https://i.imgur.com/FEqBsOc.png");
    background-size: 125%;
    background-position-x: 42%;
    background-position-y: 5%;
  }
  .movies {
    background: url("https://i.imgur.com/NaFydgI.png");
    background-size: 110%;
    background-position-x: 50%;
    background-position-y: 50%;
  }
  .zelda {
    background: url("https://i.imgur.com/iLHhiN4.png");
    background-size: 155%;
    background-position-x: 45%;
    background-position-y: 55%;
  }

  .twenty-trendy,
  .bopit,
  .hikeme,
  .barber {
    font-family: "Open Sans", cursive;
    background-color: white;
    color: black;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 2px solid rgb(66, 65, 66);
  }

  .description {
    font-family: "Open Sans", cursive;
    padding-top: 20px;
    font-size: 0.45em;
    background-color: white;
    color: black;
    width: 490.5px;
    height: 230px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 2px solid rgb(66, 65, 66);
  }

  a {
    margin: 10px;
  }

  .description-container {
    border-radius: 5%;
    font-family: lexend;
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 45vw;
    height: 92%;
    background-color: rgb(138, 108, 108);
    box-shadow: 0 0 15px rgb(212, 210, 210);
    animation: opacity-in;
    animation-duration: 1s;
  }

  .flick {
    margin: 0 auto;
    width: 40vw;
    margin-left: 1%;
  }

  @keyframes opacity-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .description-container h4 {
    border: 1px solid black;
    border-radius: 5px;
    width: fit-content;
    padding: 2px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .view {
    margin: 0 auto;
    font-size: 1.3vw;
    display: flex;
    align-items: center;
    border: solid 1px black;
    color: white;
    padding: 3px;
    width: fit-content;
    background-color: rgb(61, 61, 61);
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .view:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: ease-in 100ms;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .projects-container {
    font-family: "Lexend", sans-serif;
    font-size: 1.75vw;
    display: grid;
    justify-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    width: 63vw;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
  }

  .projects-container h3 {
    margin-bottom: 10px;
  }

  .upper-proj1-container:hover,
  .upper-proj2-container:hover,
  .lower-proj1-container:hover,
  .lower-proj2-container:hover {
    transform: scale(1.09);
    transition: 300ms ease-in-out;
    cursor: pointer;
  }

  .upper-proj1-container {
    font-size: 0.8em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .upper-proj2-container {
    font-size: 0.8em;
    text-align: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lower-proj1-container {
    font-size: 0.8em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lower-proj2-container {
    font-size: 0.8em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @keyframes upper-proj1 {
    from {
      margin-top: -90vh;
    }

    to {
      margin-top: 0;
    }
  }

  @keyframes upper-proj2 {
    from {
      margin-top: -135vh;
    }

    to {
      margin-top: 0;
    }
  }

  @keyframes lower-proj1 {
    from {
      margin-bottom: -200vh;
    }

    to {
      margin-bottom: 0;
    }
  }

  @keyframes lower-proj2 {
    from {
      margin-bottom: -500vh;
    }

    to {
      margin-bottom: 0;
    }
  }

  .bio-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 85%;
    height: 95vh;
    justify-content: center;
    align-items: center;
  }

  .me {
    width: 540px;
    height: 650px;
  }

  .biography {
    width: 33vw;
    margin-left: 6%;
    text-align: center;
    font-family: Lexend;
    font-size: 3.5vh;
  }

  .biography span {
    font-size: 0.7em;
  }
  .biography p {
    font-size: 0.7em;
    text-align: center;
  }

  .github {
    width: 2.5vw;
  }

  .linkedin {
    width: 2.5vw;
  }
  .email {
    width: 2.5vw;
  }

  .download {
    width: 2.5vw;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    list-style-type: none;
    padding: 0;
  }

  .contact-div {
    font-family: lexend;
  }

  .contact-div input,
  textarea {
    width: 95%;
    text-align: center;
    font-family: lexend;
    margin: 10px;
  }

  .contact-div textarea {
    width: 96%;
    height: 10%;
    text-align: left;
    height: 70px;
  }

  .contact-div button {
    width: 97%;
    margin-left: 2%;
    height: 40px;
    background-color: rgb(229, 244, 248);
    border-color: transparent;
    font-family: lexend;
    font-size: 1.4em;
    color: rgb(87, 85, 85);
  }

  .contact-div button:hover {
    cursor: pointer;
  }

  .contact-me:hover {
    cursor: pointer;
  }

  .project-pic {
    width: 58vh;
    height: 28vh;
  }

  .main-bio {
    margin-left: auto;
    margin-right: auto;
    margin-top: 7%;
    width: 80%;
    text-align: justify;
    font-size: 0.92vw;
  }

  .tech-stack {
    margin-top: 0;
    font-size: 1.5vh;
    margin: 0 auto;
  }

  .tech-stack div {
    text-align: center;
  }

  .view-buttons {
    margin: 0 auto;
    text-align: center;
    display: flex;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .code {
    width: 20px;
  }

  .app {
    width: 20px;
  }

  .back-arrow {
    width: 15px;
    height: 20px;
  }

  .main-project-div {
    display: flex;
    align-items: center;
  }

  .next {
    background-color: transparent;
    border-color: transparent;
    font-size: 2em;
    padding-left: 26px;
    padding-right: 19px;
    padding-top: 14px;
    padding-bottom: 9px;
    border-radius: 50%;
    color: black;
    font-family: "Nanum Myeongjo", serif;
  }

  .one {
    padding-left: 19px;
    padding-right: 26px;
    padding-top: 14px;
    padding-bottom: 9px;
    color: black;
  }

  .two:hover {
    background-color: rgba(236, 236, 236, 0.479);
    cursor: pointer;
    transition: ease-in 100ms;
  }

  .three {
    padding-left: 19px;
    padding-right: 26px;
    padding-top: 14px;
    padding-bottom: 9px;
  }

  .three:hover {
    background-color: rgba(236, 236, 236, 0.479);
    cursor: pointer;
  }

  .animate__animated.animate__backInDown {
    --animate-duration: 2s;
  }

  .container {
    position: relative;
    width: 50%;
  }

  .skills-sect {
    display: grid;
    width: 35vw;
    height: 35vh;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin-left: 4%;
  }

  .skills-sect1 {
    font-family: lexend;
    font-size: 3.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35vw;
    height: 35vh;
    margin-left: 4%;
  }

  .skills-sect1 div {
    border: solid 2px black;
    padding: 10px;
    margin-top: -9%;
    background-color: rgba(0, 0, 0, 0.308);
    color: white;
  }

  .skills-sect1 div:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: ease-in-out 200ms;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 75px;
    width: 75px;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .container:hover .overlay {
    opacity: 1;
  }

  .text {
    border-top: 1px solid white;
    font-family: lexend;
    color: white;
    font-size: 0.9em;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .top-half-bio {
    animation: fade-in-up 2.1s;
  }

  .hover-underline {
    text-decoration: underline;
    margin: 0px;
    padding: 0px;
  }

  .hover-underline:hover {
    text-decoration: none;
  }

  .bottom-container {
    width: 100%;
    animation: fade-in-down 3.3s;
  }

  @keyframes fade-in-down {
    from {
      margin-bottom: -1500%;
    }

    to {
      margin-bottom: 0%;
    }
  }

  @keyframes fade-in-up {
    from {
      margin-top: -500%;
    }

    to {
      margin-top: 0%;
    }
  }

  .bottom-container h2 {
    text-align: left;
    font-size: 1.5em;
  }

  .trait-1,
  .trait-2,
  .trait-3,
  .trait-4 {
    display: flex;
    align-items: center;
    font-size: 1vw;
    width: 100%;
    font-family: lexend;
  }

  .icon {
    margin-right: 5%;
  }

  .icon img {
    width: 8vh;
  }

  .bottom-container p {
    width: 100%;
    height: 100%;
    overflow-wrap: break-word;
  }

  .side-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 4.7vw;
    color: white;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(58, 58, 58);
  }

  .social-text {
    color: white;
    font-family: lexend;
    border-top: 1px solid white;
    margin-top: 8%;
    font-size: 0.9em;
    padding-top: 3px;
  }

  .social-container {
    font-size: 1.8vh;
    display: flex;
    opacity: 0.7;
  }

  .social-container:hover {
    opacity: 1;
    transition: ease-in 200ms;
  }

  .close-tech {
    float: right;
    margin-top: -5%;
    background-color: rgba(0, 0, 0, 0.356);
    border-color: white;
    font-family: lexend;
    font-size: 1.3em;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 50%;
    color: white;
  }

  .close-tech:hover {
    cursor: pointer;
    transition: ease-in 150ms;
  }

  .toggle-switch-div {
    font-family: "Quicksand", sans-serif;
    display: flex;
    width: fit-content;
    position: absolute;
    top: 2vh;
    right: 5vh;
    color: black;
    z-index: 1;
  }
  .toggle-switch-div div {
    margin-top: 7px;
    margin-right: 5px;
  }
  .switch {
    position: relative;
    display: block;
    width: 67px;
    height: 38px;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: -2px;
    left: 0;
    right: -1px;
    bottom: 0px;
    background: white;
    transition: 0.5s;
    border: 2px solid black;
  }

  .slider:before {
    position: absolute;
    content: "";
    bottom: 14px;
    background-color: black;
    transition: 0.5s;
  }

  .switch input {
    display: none;
  }

  input:checked + .slider {
    background-color: black;
  }

  input:checked + .slider:before {
    transform: translateX(35px) rotateZ(46deg);
    background: rgba(241, 255, 86, 255);
  }

  .slider.round {
    border-radius: 35px;
  }

  .slider.round:before {
    width: 40%;
    height: 40%;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    transform: rotateZ(-45deg);
  }

  .dark-mode-left {
    background-color: rgba(0, 0, 0, 0.822);
    animation: dark-to-light-left 700ms;
    color: rgb(202, 202, 202);
  }

  @keyframes dark-to-light-left {
    from {
      background-color: rgba(241, 255, 86, 255);
    }

    to {
      background-color: rgba(0, 0, 0, 0.822);
    }
  }

  .dark-mode-right {
    background-color: rgb(46, 90, 104);
    animation: dark-to-light-right 500ms;
    color: rgb(202, 202, 202);
  }

  @keyframes dark-to-light-right {
    from {
      background-color: rgb(255, 255, 255);
    }

    to {
      background-color: rgb(46, 90, 104);
    }
  }

  @keyframes light-to-dark-right {
    from {
      background-color: rgb(27, 70, 90);
    }

    to {
      background-color: rgba(241, 255, 86, 255);
    }
  }
}
